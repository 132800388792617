<template>
  <Page title="Browse Farms">
    <v-form @submit.prevent="">
      <v-row dense>
        <v-col cols="12" :sm="hasMultipleClients ? 4 : 8">
          <v-text-field
            v-model="options.params.search"
            label="Search"
            @input="filterFarms"
            @change="filterFarms"
            @click:clear="onClearSearch"
            @keydown.esc="onClearSearch"
            @keydown.enter.prevent
            :append-icon="
              options.params.search.length ? 'mdi-close' : 'mdi-magnify'
            "
            @click:append="onClearSearch"
          ></v-text-field>
        </v-col>
        <v-col v-if="hasMultipleClients" cols="12" sm="4">
          <v-select
            :items="clients"
            item-value="id"
            item-text="title_with_id"
            v-model="options.params.client_id"
            label="Client"
            clearable
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-radio-group v-model="options.params.is_active">
            <v-row dense>
              <v-col>
                <v-radio label="Show Active" value="1"></v-radio>
              </v-col>
              <v-col>
                <v-radio label="Show All" value=""></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table v-bind="customAttrs" :options.sync="options">
      <template v-slot:item.title="{ item }">
        <v-tooltip
          bottom
          v-if="item.title && item.title.length && item.title.length > 15"
          max-width="50%"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              style="max-width: 8rem; 
              text-overflow: ellipsis; 
              white-space: nowrap; 
              overflow: hidden;"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.title }}
            </p>
          </template>
          <template v-slot:default>
            <div class="custom-tooltip">
              {{ item.title }}
            </div>
          </template>
        </v-tooltip>
        <p
          v-else
          style="max-width: 8rem; 
          text-overflow: ellipsis; 
          white-space: nowrap; 
          overflow: hidden;"
        >
          {{ item.title }}
        </p>
      </template>
      <template v-slot:item.email="{ item }">
        <v-tooltip
          bottom
          v-if="item?.email && item?.email.length && item?.email.length > 15"
          max-width="50%"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              style="max-width: 8rem;
              text-overflow: ellipsis; 
              white-space: nowrap; 
              overflow: hidden;"
              v-bind="attrs"
              v-on="on"
            >
              {{ item?.email }}
            </p>
          </template>
          <template v-slot:default>
            <div class="custom-tooltip">
              {{ item?.email }}
            </div>
          </template>
        </v-tooltip>
        <p
          v-else
          style="max-width: 8rem; 
          text-overflow: ellipsis; 
          white-space: nowrap; 
          overflow: hidden;"
        >
          {{ item.email }}
        </p>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="editItem(item)" v-bind="attrs" v-on="on"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Edit Farm</span>
        </v-tooltip>
        <span v-if="item.is_active">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="toggleActivation(item)" v-bind="attrs" v-on="on"
                >mdi-cancel</v-icon
              >
            </template>
            <span>Deactivate Farm</span>
          </v-tooltip>
        </span>
        <span v-else>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="toggleActivation(item)" v-bind="attrs" v-on="on"
                >mdi-check</v-icon
              >
            </template>
            <span>Activate Farm</span>
          </v-tooltip>
        </span>
        &nbsp;<router-link :to="sourcesUrl(item)" style="text-decoration: none;"
          >Manage Sources</router-link
        >
      </template>
      <template v-slot:item.is_active="{ item }">
        <v-icon v-if="item.is_active" color="green">mdi-check</v-icon>
        {{ item.is_active ? "Active" : "Inactive" }}
      </template>
    </v-data-table>
    <v-btn color="primary" @click="addFarm">Add Farm</v-btn>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import { mapActions, mapGetters, mapState } from "vuex";
import dataTablePagination from "@/mixins/dataTablePagination";

export default {
  name: "FarmsBrowse",
  components: { Page },
  mixins: [dataTablePagination],
  data() {
    return {
      loading: true,
      search: "",
      show: "active",
      detectedField: "",
      headers: [
        { text: "Name", value: "title" },
        { text: "Number", value: "number" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Status", value: "is_active" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      options: {
        params: {
          // title: "",
          // client_id: "",
          // number: "",
          // phone: "",
          // email: "",
          search: "",
          is_active: "1"
        }
      },
      shownFarms: []
    };
  },
  computed: {
    ...mapGetters("clients", ["hasMultipleClients"]),
    ...mapState("clients", { clients: "items" }),
    customAttrs() {
      return {
        ...this.defaultAttrs
      };
    },
    // shownFarms() {
    //   // // Convert the search term to lowercase for case-insensitive comparison
    //   let searchTerm = this.search && this.search.toLowerCase();

    //   // // If the search term is empty, show all farms based on the 'show' filter (active/all)
    //   // if (!searchTerm) {
    //   //   return this.items;
    //   // }

    //   // // Filter the farms based on the search term and selected 'show' filter
    //   // return this.items.filter(
    //   //   farm =>
    //   //     farm.title
    //   //       .toLowerCase()
    //   //       .trim()
    //   //       .includes(searchTerm.trim()) ||
    //   //     farm.number
    //   //       .toLowerCase()
    //   //       .trim()
    //   //       .includes(searchTerm.trim()) ||
    //   //     farm.phone
    //   //       .toLowerCase()
    //   //       .trim()
    //   //       .includes(searchTerm.trim()) ||
    //   //     farm.email
    //   //       .toLowerCase()
    //   //       .trim()
    //   //       .includes(searchTerm.trim())
    //   // );

    // },
    dynamicModel: {
      get() {
        return this.options.params[this.detectedField];
      },
      set(value) {
        this.options.params[this.detectedField] = value;
      }
    }
  },
  methods: {
    ...mapActions("farms", ["getPaginated", "editFarm"]),
    toggleActivation(item) {
      this.editFarm({ id: item.id, is_active: !item.is_active }).then(() => {
        this.getPaginatedProxy();
      });
    },
    editItem(item) {
      this.$router.push(`/farms/edit/${item.id}`);
    },
    addFarm() {
      this.$router.push("/farms/add");
    },
    sourcesUrl(item) {
      return `/farms/${item.id}/sources`;
    },
    filterFarms() {
      // let searchTerm = this.search && this.search.toLowerCase().trim();
      // if (
      //   this.searchTerm != "" &&
      //   (this.items.some(item =>
      //     item.title
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   ) ||
      //     this.items.some(item =>
      //       item.number
      //         .toLowerCase()
      //         .trim()
      //         .includes(searchTerm)
      //     ) ||
      //     this.items.some(item =>
      //       item.phone
      //         .toLowerCase()
      //         .trim()
      //         .includes(searchTerm)
      //     ) ||
      //     this.items.some(item =>
      //       item.email
      //         .toLowerCase()
      //         .trim()
      //         .includes(searchTerm)
      //     ))
      // ) {
      //   this.options.params.title = this.items.some(item =>
      //     item.title
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      //     ? searchTerm
      //     : "";
      //   this.options.params.number = this.items.some(item =>
      //     item.number
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      //     ? searchTerm
      //     : "";
      //   this.options.params.phone = this.items.some(item =>
      //     item.phone
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      //     ? searchTerm
      //     : "";
      //   this.options.params.email = this.items.some(item =>
      //     item.email
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      //     ? searchTerm
      //     : "";
      // } else if (searchTerm == "") {
      //   this.options.params = {
      //     is_active: this.options.params.is_active
      //   };
      // } else {
      //   this.items = [];
      // }
      // if (
      //   this.items.some(item =>
      //     item.title
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      // ) {
      //   this.options.params.title = searchTerm;
      // } else if (
      //   this.items.some(item =>
      //     item.number
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      // ) {
      //   this.options.params.number = searchTerm;
      // } else if (
      //   this.items.some(item =>
      //     item.phone
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      // ) {
      //   this.options.params.phone = searchTerm;
      // } else if (
      //   this.items.some(item =>
      //     item.email
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      // ) {
      //   this.options.params.email = searchTerm;
      // }
      this.getPaginatedProxy();
    },
    onClearSearch() {
      // this.detectedField = ""; // Reset to default
      // this.filterFarms();
      this.options.params.search = "";
      // this.options.params = {
      //   is_active: this.options.params.is_active
      // };
    }
  }
};
</script>

<style scoped></style>
